<template>
  <v-icon
    class="tooltip-icon"
    v-tooltip="{
      content: text,
      placement: 'top-center',
      trigger: 'click hover',
    }"
  >
    {{ icon }}
  </v-icon>
</template>

<script>
export default {
  name: "IconWithTooltip",
  props: {
    icon: {
      type: String,
      default: "info"
    },
    text: {
      type: String,
      required: true
    }
  }
}
</script>