<template>
  <ul class="text-xs-justify">
    <li>Make sure that device is registered to your account under the <strong>Device Management</strong> page.</li>
    <li class="mb-3">Make sure you are standing at close proximity to the device you want to connect to.</li>
    <li class="mb-3">Make sure the Plugzio device is powered on.</li>
    <li class="mb-3">
      Make sure the device is not connected to the internet - Plugzio devices only enable their bluetooth when there is no internet (i.e. breathing red LED). To check if the device is connected to the
      internet, try registering or accessing it from the <strong><a class="plugzio-color--text" @click.stop.prevent="$router.push({ name: `${modeRoute}dashboard` })">Dashboard</a></strong
      >.
    </li>
    <li>
      Contact <strong><a class="plugzio-color--text" :href="contactUs" target="_blank">Plugzio Support</a></strong> and we can assist you!
    </li>
  </ul>
</template>

<script>
import mixin from "./../mixins";
export default {
  mixins: [mixin],
};
</script>
