<template>
  <div id="app">
    <v-app :dark="darkTheme">
      <v-content
        :class="{
          'v-content-with-toolbar': isDashboard,
          'v-content-with-toolbar-show-sidebar': isDashboard && $store.state.Global.showSidebar,
          'v-content-responsive': $store.state.responsive,
        }"
      >
        <NavBar v-if="isDashboard" />
        <div class="pb-5rem fill-height full-inner-height">
          <v-fade-transition v-if="!isOffline" mode="out-in">
            <router-view
              v-if="ownerProxyInitialized"
              :key="actAsOwner"
            />
            <div v-else class="d-flex justify-center align-center h-full">
              <v-progress-circular
                class="loading-circle mb-3"
                size="50"
                indeterminate
                color="orange"
              />
            </div>
          </v-fade-transition>
          <Offline v-else />
        </div>

        <Policy
          v-if="isDashboard"
          class="policy-footer"
          :layout="$store.state.responsive ? 'column' : 'row'"
        />
        <v-snackbar v-model="snackbar.show" bottom :timeout="3000">
          {{ snackbar.message }}
          <v-btn class="snackbar-close-button" icon color="#bbbbbb" flat @click="$store.dispatch('snackbar', { show: false })">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-snackbar>
        <v-snackbar :key="JSON.stringify(offlineModeSnackbar)" v-model="offlineModeSnackbar.show" bottom :timeout="offlineModeSnackbar.timeout" :color="offlineModeSnackbar.color">
          {{ offlineModeSnackbar.message }}
          <v-btn class="snackbar-close-button" icon color="#bbbbbb" flat @click="$store.commit('Global/setOffline', { offlineModeSnackbar: { show: false } })">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-snackbar>
        <PlugCloseToExpiration v-if="role == 'owner'" :timestamp="plugClosestExpirationTimestamp" v-model="showPlugCloseToExpirationModal"></PlugCloseToExpiration>
      </v-content>
    </v-app>
    <OpenAppSnackbar v-if="showOpenAppSnackbar" />
    <GlobalDialog />
    <Intercom />
  </div>
</template>

<script>
import mixin from "./mixins";
import { mapGetters, mapState } from "vuex";
import Api from "./library/apis/Api";
import { Capacitor } from "@capacitor/core";
import { App } from "@capacitor/app"
import { capitalize } from "lodash-es";
import PlugCloseToExpiration from "./components/modals/PlugCloseToExpiration";
import NavBar from "@/components/NavBar";
import OpenAppSnackbar from "@/components/OpenAppSnackbar.vue";
import Offline from "@/components/Offline.vue"
import Policy from "@/components/policy/Policy.vue";
import GlobalDialog from "@/components/modals/GlobalDialog.vue";
import Intercom from "@/components/intercom/Intercom.vue";
import { AUTHENTICATED_ROUTES } from "@/constants"

export default {
  // https://dev.to/js_bits_bill/dynamic-open-graph-meta-tags-with-vuejs-and-node-js-bits-2a11
  metaInfo() {
    return {
      title: this.title,
      titleTemplate: `%s - Plugzio`,
      meta: [
        { name: "title", content: `Plugzio ${capitalize(this.role)} Dashboard` },
        { name: "og:title", content: `Plugzio ${capitalize(this.role)} Dashboard` },
        {
          name: "description",
          content: this.role == "user" ? `Login to activate a device monitor usage and access your wallet` : "Login to manage your devices, view analytics and access your wallets",
        },
        {
          name: "og:description",
          content: this.role == "user" ? `Login to activate a device monitor usage and access your wallet` : "Login to manage your devices, view analytics and access your wallets",
        },
        { name: "og:url", content: window.location.origin + window.location.pathname },
        { name: "og:image", content: window.location.origin + window.location.pathname + "/static/PlugzioLogoHorizontal.png" },
        { name: "twitter:image", content: window.location.origin + window.location.pathname + "/static/favicon-152x152.png" },
      ],
    };
  },
  mixins: [mixin],
  components: {
    NavBar,
    PlugCloseToExpiration,
    OpenAppSnackbar,
    Offline,
    Policy,
    GlobalDialog,
    Intercom
  },
  data() {
    return {
      actionSub: null,
    };
  },
  computed: {
    ...mapState({
      showOpenAppSnackbar: (state) => state.Global.showOpenAppSnackbar,
      isOffline: state => state.Global.offline,
      intercom: state => state.Global.intercom,
      actAsOwner: state => state.OwnerProxy.actAs,
    }),
    ...mapGetters(["plugClosestExpirationTimestamp"]),
    ...mapGetters("Auth", {
      roleSetting: "getRoleSetting",
    }),
    ...mapGetters("Global",{
      reloadOnStateChange: "reloadOnStateChange"
    }),
    ...mapGetters("OwnerProxy", {
      ownerProxyInitialized: "initialized",
    }),
    snackbar: {
      get() {
        return this.$store.state.snackbar;
      },
      set(val) {
        val || this.$store.dispatch("snackbarHide");
      },
    },
    offlineModeSnackbar: {
      get() {
        return this.$store.state.Global.offlineModeSnackbar;
      },
      set(val) {
        if (!val) this.$store.commit("Global/setOffline", { offlineModeSnackbar: { show: false } });
      },
    },
    showPlugCloseToExpirationModal: {
      get() {
        if (!this.plugClosestExpirationTimestamp) return false;
        const is_dismissed = localStorage.getItem("__plugzio_plug_expiration_notification_dismissed__");
        return is_dismissed === "false";
      },
      set(value) {
        if (value === false) localStorage.setItem("__plugzio_plug_expiration_notification_dismissed__", true);
      },
    },
    isApp() {
      return Capacitor.isNativePlatform();
    },
    title() {
      let route = this.$route.name || "";
      let title = route.replace(/-+/g, " ").replace(/(?:^|\s)\S/g, (a) => {
        return a.toUpperCase();
      });
      return title.replace("Owner", "Management");
    },
    darkTheme() {
      return ((this.$store.state.Global.preferences || {}).settings || {}).darkTheme || false
    }
  },
  watch: {
    $route() {
      this.$store.dispatch("Admin/setActiveView", this.$route.name);
      // New version of capacitor required splash screen, so everytime location reloaded, the splash screen appears
      if (this.isApp && this.reloadOnStateChange) location.reload()
    }
  },
  methods: {
    async processStoredBleSessions() {
      console.groupCollapsed("[STORED BLE SESSIONS PROCESSING] Processing stored BLE sessions...");
      if (!navigator.onLine) {
        console.error("[STORED BLE SESSIONS PROCESSING] No internet connection detected, please connect to internet to upload the processed BLE sessions");
        console.groupEnd();
        return;
      }
      let cache = JSON.parse(localStorage.getItem("__plugzio_storage__"));
      if (!cache) console.log("[STORED BLE SESSIONS PROCESSING] No stored sessions detected...");

      for (const email in cache) {
        console.groupCollapsed("[STORED BLE SESSIONS PROCESSING] Processing stored BLE sessions for user " + email);
        const bleDevices = cache[email].hasOwnProperty("bleDevices") ? cache[email].bleDevices : [];
        if (bleDevices.length == 0) console.log("[STORED BLE SESSIONS PROCESSING] No devices found");
        for (let index = 0; index < bleDevices.length; index++) {
          const bleDevice = bleDevices[index];
          console.groupCollapsed("[STORED BLE SESSIONS PROCESSING] Processing ble with device id : " + bleDevice.device_id);
          const signature = bleDevice.signature;
          const chunks = bleDevice.chunks;
          const sessions = bleDevice.sessions;
          if (sessions.length > 0 && chunks && signature) {
            console.log("[STORED BLE SESSIONS PROCESSING] Uploading ble sessions...");
            await Api.plugPostAsyncSessions("user", `{"plugIdentifier":"${bleDevice.device_id}", "signature":"${signature}","sessionsChunks":${chunks}}`)
              .then(async () => {
                console.log("[STORED BLE SESSIONS PROCESSING] Stored sessions successfully uploaded. Removing uploaded sessions...");
                this.$store.dispatch("setBleDevice", {
                  device_id: bleDevice.device_id,
                  properties: { sessions: [], signature: null, chunks: null },
                  email: email,
                });

                console.log("[STORED BLE SESSIONS PROCESSING] Renew Device token");
                await Api.plugGetAsyncToken("user", `{"plugIdentifier":"${bleDevice.device_id}"}`)
                  .then((data) => {
                    this.$store.dispatch("setBleDevice", {
                      device_id: bleDevice.device_id,
                      properties: { credential: data },
                      email: email,
                    });
                    console.log("[STORED BLE SESSIONS PROCESSING] Successfully renew device token");
                  })
                  .catch((error) => console.error("[STORED BLE SESSIONS PROCESSING] " + error));
              })
              .catch((error) => console.error("[STORED BLE SESSIONS PROCESSING] " + error));
          } else console.log("[STORED BLE SESSIONS PROCESSING] No sessions detected, stopping the process");

          console.groupEnd();
        }
        console.groupEnd();
      }
      console.groupEnd();
    },
    handleNetworkChange(event) {
      this.$store.commit("Global/setOffline", {
        offline: !navigator.onLine,
        offlineModeSnackbar : {
          message: navigator.onLine ? "You've back online" : "You are currently offline",
          timeout: navigator.onLine ? 3000 : 0,
          show: true,
          color: navigator.onLine ? "#2b8a2b" : "#212121",
        }
      });

      if (navigator.onLine) setTimeout(location.reload(), 3000)
    },
  },
  async created() {
    if (window.version) console.log("version : " + window.version);
    if (!AUTHENTICATED_ROUTES.includes(this.$route.name)) {
      this.$store.dispatch("OwnerProxy/resetState")
      this.$store.commit("OwnerProxy/setState", { initialized: true })
    }
  },
  mounted() {
    this.$store.dispatch("Admin/setActiveView", this.$route.name);
    this.$store.dispatch("Global/init");
    this.$store.dispatch("setTime", this.timeSpan);
    console.log("is mobile application: " + this.$root.isMobileApplication);

    // network event listeners
    if (!navigator.onLine) this.handleNetworkChange(null);
    window.addEventListener("online", this.handleNetworkChange);
    window.addEventListener("offline", this.handleNetworkChange);

    // app active event listeners
    if (this.isApp) {
      App.addListener("appStateChange", ({ isActive }) => {
        console.log("App state changed. Active =", isActive)
        if (isActive && this.reloadOnStateChange) location.reload()
      })
    }

    if (this.role == "user" && !this.isApp) {
      console.log("User Agent:", navigator.userAgent);
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.$store.commit("Global/setShowOpenAppSnackbar", true);
      }
    }

    if (this.role != "admin") {
      this.processStoredBleSessions();
      this.$router.afterEach((to, from) => {
        this.processStoredBleSessions();
      });
    }

    this.actionSub = this.$store.subscribeAction((action) => {
      if (action.type === "Auth/redirectToLogin") {
        this.$nextTick(() => {
          if (this.$route.name !== `${this.roleSetting.routePrefix}login`) this.$router.push({ name: `${this.roleSetting.routePrefix}login` });
        });
      }
    });
  },
  beforeDestroy() {
    this.actionSub();
    window.removeEventListener("online", this.handleNetworkChange);
    window.removeEventListener("offline", this.handleNetworkChange);
  },
};
</script>

<style lang="scss">
@import "./styles/colors";
@import "./styles/app.scss";

a {
	text-decoration: none;
	color: green;
}

.container {
	@media only screen and (max-width: 460px) {
		padding: 16px 6px 6px
  }
}

#app {
	font-family: Roboto, sans-serif;
}

h1 {
	font-weight: 300;
	font-size: 1.6em;
	margin: 0 0 10px 10px;
	@media only screen and (max-width: 960px) {
		font-size: 16px;
		margin: 6px 0 10px 10px;
		font-weight: 600;
  }
}

.main-drawer-menu {
	.v-list__tile__title {
		font-weight: 400
  }
}

.card-tile-header {
	h3 {
		font-weight: 500;
  }
}

button {
	outline: none;
}

.v-content-with-toolbar {
	padding-top: 58px !important;
}

.v-alert {
	width: 100%;
	margin: 0;
	text-align: left;
}

.plugzio-color--text {
	color: #0d920d !important;
}

.plugzio-button {
	color: #0d920d !important;
	border: 1px solid #0d920d;
	min-width: 140px;
  &:disabled, &.v-btn.v-btn--disabled {
    color: #9a9a9a !important;
    border: 1px solid #9a9a9a;
    .v-icon {
      color: #9a9a9a !important;
    }
  }
}

.plugzio-danger-button {
	color: #ff5252 !important;
	border: 1px solid #0d920d;
	min-width: 140px;
}

.create-button {
	margin-top: -4px;
	@media only screen and (max-width: 960px) {
		margin-top: 1px;
  }
}

.title-menu {
	font-size: 14px;
	margin: 0;
	@media only screen and (max-width: 420px) {
		font-size: 12px;
  }
}

.page-toolbar {
	margin-top: -5px;
	@media only screen and (max-width: 960px) {
		padding: 4px 9px 0 0;
  }
}

.page-toolbar-btn {
	text-transform: none;
	min-width: auto !important;
	@media only screen and (max-width: 460px) {
		margin-right: 0;
  }
}

.v-list {
	.menu-active, .v-list__tile--active {
		background: $user-color !important;
		color: #ffffff !important;
  }
}

.v-input__prepend-inner {
	padding-right: 10px !important;
}

.theme--light.v-text-field--outline .v-input__slot {
	border: 1px solid #f1f1f1 !important;
	background: #f9f9f9 !important;
	border-radius: 4px;
}

.theme--light.v-messages {
	color: rgba(0,0,0,0.64);
}

.snackbar-close-button {
	margin: 0 -12px 0 20px !important;
}

.tile-button {
	border: 2px solid #0d920d;
	padding: 2em 0 2em 0;
}

.date-range {
	margin: -5px 0 0 0;
}

.document-container {
	margin: 0 auto;
	max-width: 900px;
}

.document {
	h2 {
		text-align: center;
  }
	h1 {
		margin: 30px 10px 10px 0 !important;
  }
}

.notice {
	font-size: 1.4em;
	font-weight: 300;
	.notice-description {
		font-size: .8em;
  }
	h4 {
		font-weight: 400;
  }
	@media only screen and (max-width: 460px) {
		font-size: 1.2em;
  }
}

.modal-description {
	font-size: .9em;
	margin: -1em 0 0;
	color: gray;
}

.info-icon {
	margin: 0 6px -3px  0;
}

.pace {
	-webkit-pointer-events: none;
	pointer-events: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	.pace-progress {
		background: orange;
		position: fixed;
		z-index: 2000;
		top: 0;
		right: 100%;
		width: 100%;
		height: 3px;
  }
}

.pace-inactive {
	display: none;
}

.pointer {
	cursor: pointer !important;
}

.pull-right {
	float: right;
}

.rotate-icon {
	transform: scaleY(-1);
}

.opacity-0 {
	opacity: 0;
}

.opacity-50 {
	opacity: .5;
}

.full-inner-height {
	height: 100%;
}

.white-bg {
	background: #ffffff;
}

.center-margin {
	margin-left: auto;
	margin-right: auto;
}

.p-relative {
	position: relative;
}

.theme--light.v-label {
	color: rgba(0,0,0,0.75);
}

.hide {
	display: none !important;
}

.help-button {
	cursor: pointer !important;
}

.tooltip-icon {
	color: #9a9a9a !important
}

.tooltip-icon:hover {
	color: #333333 !important
}

.tooltip {
	display: block !important;
	z-index: 201;
	font-family: Roboto, sans-serif;
	font-size: 12px;
	line-height: 1.5em;
	.tooltip-inner {
		background: rgba(0,0,0,.75);
		color: white;
		border-radius: 3px;
		padding: 12px 16px;
  }

	.tooltip-arrow {
		width: 0;
		height: 0;
		border-style: solid;
		position: absolute;
		margin: 5px;
		border-color: rgba(0,0,0,.75);
		z-index: 1;
  }

	&[x-placement^="top"] {
		margin-bottom: 5px;
		.tooltip-arrow {
			border-width: 5px 5px 0 5px;
			border-left-color: transparent !important;
			border-right-color: transparent !important;
			border-bottom-color: transparent !important;
			bottom: -5px;
			left: calc(50% - 5px);
			margin-top: 0;
			margin-bottom: 0;
    }

  }

	&[x-placement^="bottom"] {
		margin-top: 5px;
		.tooltip-arrow {
			border-width: 0 5px 5px 5px;
			border-left-color: transparent !important;
			border-right-color: transparent !important;
			border-top-color: transparent !important;
			top: -5px;
			left: calc(50% - 5px);
			margin-top: 0;
			margin-bottom: 0;
    }
  }

	&[x-placement^="right"] {
		margin-left: 5px;
		.tooltip-arrow {
			border-width: 5px 5px 5px 0;
			border-left-color: transparent !important;
			border-top-color: transparent !important;
			border-bottom-color: transparent !important;
			left: -5px;
			top: calc(50% - 5px);
			margin-left: 0;
			margin-right: 0;
    }
  }

	&[x-placement^="left"] {
		margin-right: 5px;
		.tooltip-arrow {
			border-width: 5px 0 5px 5px;
			border-top-color: transparent !important;
			border-right-color: transparent !important;
			border-bottom-color: transparent !important;
			right: -5px;
			top: calc(50% - 5px);
			margin-left: 0;
			margin-right: 0;
    }

  }

	&[aria-hidden='true'] {
		visibility: hidden;
		opacity: 0;
		transition: opacity .15s, visibility .15s;
  }

	&[aria-hidden='false'] {
		visibility: visible;
		opacity: 1;
		transition: opacity .15s;
  }
}

.solo-button .v-input__slot {
	border: 1px solid #f1f1f1 !important;
	background: #f9f9f9 !important;
	border-radius: 4px;
	box-shadow: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	-webkit-box-shadow: 0 0 0px 1000px rgba(249, 249, 249, 1) inset;
  -webkit-text-fill-color: black !important;
	transition: background-color 5000s ease-in-out 0s;
}
</style>
<style lang="scss">
@import "./styles/colors";

html,
body {
  margin: 0;
  bottom: 0;
  background: #f0f4f3 !important;
}
body,
.v-toolbar--fixed,
.v-navigation-drawer--fixed {
  padding-top: env(safe-area-inset-top) !important;
}
.theme--light {
  .v-toolbar {
    background-color: $user-color !important;
  }
  .v-toolbar__content {
    background-color: white;
  }
}

.owner-mode {
  .v-list {
    .menu-active,
    .v-list__tile--active {
      background: $owner-color !important;
      color: #ffffff !important;
    }
  }
  .pace {
    .pace-progress {
      background: $user-color !important;
    }
  }
}
.user-mode {
  .v-list {
    .menu-active,
    .v-list__tile--active {
      background: $user-color !important;
      color: #ffffff !important;
    }
  }
  .pace {
    .pace-progress {
      background: $user-color !important;
    }
  }
}
.admin-mode {
  .v-list {
    .menu-active,
    .v-list__tile--active {
      background: $admin-color !important;
      color: #ffffff !important;
    }
  }
  .pace {
    .pace-progress {
      background: $user-color !important;
    }
  }
}
#app {
  user-select: text;
}
// body {
//   margin-top: constant(safe-area-inset-top);
//   margin-top: env(safe-area-inset-top);
//   padding-top: constant(safe-area-inset-top);
//   padding-top: env(safe-area-inset-top);
// }
</style>
