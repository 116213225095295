<template>
  <div id="device-activation-payment-plan">
    <v-progress-circular class="loading-circle mb-3" size="24" indeterminate color="orange" v-if="loading" />
    <div v-else-if="userAccess">
      <div v-if="userAccess.mode === 0" class="error--text">
        This device has been disabled by management. Please contact management for more information.
      </div>
      <div v-else-if="userAccess.mode === 1" class="success--text">
        This device is configured to deliver electricity when you plug in. No activation is needed!
      </div>
      <div v-else-if="userAccess.haveAccess">
        <div v-if="!currencyCodes.includes(userAccess.currency)" class="error--text">
          Payment plan currency is invalid. Please contact the manager of the device or customer support.
        </div>
        <template v-else-if="userAccess.accessPlan && !userAccess.accessPlan.timezone">
          <template v-if="userAccess.accessPlan.startSessionCost > 0 || userAccess.accessPlan.hRate > 0 || userAccess.accessPlan.whRate > 0">
            <div v-if="userAccess.currency">
              Currency:
              <strong>{{ userAccess.currency }}</strong>
            </div>
            <div v-if="userAccess.accessPlan.startSessionCost > 0">
              Activation Fee <span v-if="userAccess.currency">({{ userAccess.currency }})</span>:
              <strong>{{ sessionCost }}</strong>
            </div>
            <div v-if="userAccess.accessPlan.hRate && userAccess.accessPlan.hRate > 0">
              Duration Rate <span v-if="userAccess.currency">({{ userAccess.currency }})</span>:
              <strong>{{ durationRate }}</strong>
            </div>
            <div v-if="userAccess.accessPlan.whRate && userAccess.accessPlan.whRate > 0">
              Consumption Rate <span v-if="userAccess.currency">({{ userAccess.currency }})</span>:
              <strong>{{ consumptionRate }}</strong>
            </div>
          </template>
          <div v-else class="success--text">There is no charge for using this device</div>
        </template>
        <div v-else-if="userAccess.accessPlan">
          <div v-if="userAccess.currency">
            Currency:
            <strong>{{ userAccess.currency }}</strong>
          </div>
          <ConditionalPlanPricing class="pt-2" :payment-plan="userAccess.accessPlan" :currency="userAccess.currency" />
        </div>
        <div v-else-if="userAccess.isAnonymous" class="success--text">
          The device does not need activation. Just plug right in!
        </div>
        <div v-else class="error--text">
          Error: No payment plan assigned
        </div>
      </div>
      <div v-else class="error--text">Access to this device is restricted. For access, please contact management.</div>
    </div>
  </div>
</template>

<script>
import mixin from "@/mixins/helpers";
import ConditionalPlanPricing from "@components/conditional-plan/ConditionalPlanPricing.vue"
import currencyCodes from "@/data/currencyCodes.json";
export default {
  components: { ConditionalPlanPricing },
  mixins: [mixin],
  props: {
    userAccess: {
      type: Object,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    rate() {
      return this.userAccess.accessPlan.whRate ? this.userAccess.accessPlan.whRate : 0;
    },
    sessionCost() {
      return this.formatCost(this.userAccess.accessPlan.startSessionCost);
    },
    durationRate() {
      return this.formatRate(this.userAccess.accessPlan.hRate);
    },
    consumptionRate() {
      return this.formatRate((this.rate * 1000000000) / 1000000, "kWh");
    },
    currencyCodes: () => currencyCodes,
  },
};
</script>
