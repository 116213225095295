<template>
  <v-card class="access-tile ma-2">
    <v-card-title @click.stop="allowChangeMode ? show = !show : !show">
      <h3>Device Power &amp; Access Settings</h3>
      <v-icon
        class="tooltip-icon ml-2"
        v-tooltip="{
          content: tooltip,
          trigger: 'click hover',
        }"
        >info</v-icon
      >
      <v-spacer></v-spacer>
      <v-btn flat small icon class="ma-0" @click.stop="show = !show" :disabled="!allowChangeMode">
        <v-icon v-if="show">keyboard_arrow_up</v-icon>
        <v-icon v-else>keyboard_arrow_down</v-icon>
      </v-btn>
    </v-card-title>
    <div v-if="show">
      <v-alert :id="modeError === 'Some devices failed to update' ? `error-set-mode-attempt` : ``" :value="modeError" type="error" dismissible @input="dismissAlert">{{ modeError }}</v-alert>
      <v-card-text class="pb-0">
        <div v-if="!selectPlugsStep">
          <v-select
            class="mt-3"
            v-model="modeChange.code"
            :items="plugModes"
            item-text="name"
            item-value="code"
            label="Power Settings"
            outline
            required
            :error="modeError || selectError ? true : false"
            :disabled="loading"
            :hint="modeChange.code !== null ? selectedMode.description : null"
            persistent-hint
          ></v-select>
          <v-select
            v-if="modeChange.code > 1"
            v-model="modeChange.ispublic"
            :items="subModes"
            item-text="name"
            item-value="code"
            label="Access"
            outline
            required
            :error="modeError ? true : false"
            :disabled="loading"
            :hint="accessDescription || false"
            persistent-hint
          ></v-select>
          <v-autocomplete
            v-if="modeChange.code > 1 && modeChange.ispublic"
            v-model="modeChange.plan_id"
            :items="visiblePayments"
            item-text="name"
            item-value="id"
            label="Payment Plan"
            no-data-text="No payment plans available"
            outline
            required
            :error="modeError || planError ? true : false"
            :disabled="loading"
            hint="Default payment plan for users with no access."
            persistent-hint
          />
        </div>
        <div v-else>
          Mode:
          <strong>{{ selectedMode.name }}</strong>
          <div v-if="modeChange.code >= 2">
            Access:
            <strong>{{ selectedSubMode.name }}</strong>
            <br />
            <div v-if="modeChange.ispublic">
              Payment Plan:
              <strong>{{ selectedPlan.params.description }}</strong>
              <br />Currency:
              <strong>{{ selectedPlan.wallet.currency }}</strong>
              <br />Activation Fee:
              <strong>{{ formatCost(selectedPlan.params.startSessionCost) }}</strong>
              <br />Duration Rate:
              <strong>{{ formatRate(selectedPlan.params.hRate) }}</strong>
            </div>
          </div>
          <div>
            <v-text-field
              max-width="300"
              class="mb-0 mx-2"
              v-model.lazy="search"
              :placeholder="!enableRegexSearch ? 'Search' : 'Search RegEx'"
              autocomplete="false"
              prepend-icon="search"
            >
              <template v-if="enableRegexSearch" v-slot:prepend-inner>
                <v-flex>
                  <i class="search-slash-icon" style="bottom:-4px;position:relative">/</i>
                </v-flex>
              </template>
              <template v-slot:append>
                <v-flex align-self-center class="search-append-div">
                  <i v-if="enableRegexSearch" class="search-slash-icon mr-2" style="bottom:3px;position:relative">/</i>

                  <v-icon
                    @click="enableRegexSearch = !enableRegexSearch"
                    class="tooltip-icon"
                    :color="enableRegexSearch ? `blue lighten-2` : ''"
                    v-tooltip="{
                      content: 'Allows search results to be filtered using regular expression (RegEx) patterns. RegEx flavour is JavaScript/ECMAScript.',
                      trigger: 'hover',
                    }"
                    >settings_applications</v-icon
                  >
                </v-flex>
              </template>
            </v-text-field>
          </div>
          <div class="text-xs-right mb-2 p-relative" v-if="userPlugs">
            <a class="d-inline-block mr-2 select-all" @click.stop.prevent="selectAll = !selectAll">{{ selectAll ? "Select None &nbsp;&nbsp;" : "Select All &nbsp;&nbsp;" }}</a>
            <v-checkbox class="d-inline-block mt-0 mb-0 mr-1" style="padding-right: 1px" color="green" v-model="selectAll" :disabled="loading" hide-details />
          </div>
          <div v-else class="modal-description mt-1 mb-3">No devices available</div>
          <v-list v-if="userPlugs" class="pa-0 ma-0 mt-0 mb-2 access-tile-plugs" :key="key">
            <v-list-tile v-for="(plug, index) in userPlugs" :key="index" class="ma-0 access-tile-plugs-list" @click="(evt) => clickCheckbox(evt, index)">
              <v-list-tile-content>
                <v-list-tile-title class="user-name">{{ plug.identifier + " - " + plug.description }}</v-list-tile-title>
              </v-list-tile-content>
              <v-list-tile-action class="text-xs-right">
                <div style="checkbox-container">
                  <v-checkbox :id="`checkbox_${index}`" :key="search + '_' + key" color="green" v-model="plug.selected" @change="select(plug)" :disabled="loading" hide-details />
                </div>
              </v-list-tile-action>
            </v-list-tile>
          </v-list>
        </div>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-btn v-if="selectPlugsStep" @click="back()" round outline small>BACK</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="green" v-if="!selectPlugsStep" @click="filterModePlugs()" :loading="loading" round outline small>NEXT</v-btn>
        <v-btn v-else-if="userPlugs" color="green" @click="confirmChange()" :loading="loading" round outline small>SET MODE</v-btn>
      </v-card-actions>
    </div>
    <v-dialog v-model="confirmDialog" max-width="320">
      <v-card>
        <v-card-title>
          <h3>Change Device Mode</h3>
        </v-card-title>
        <v-card-text v-if="hasUsers">Changing the mode of device(s) will interrupt any ongoing user session(s). Are you sure you would like to continue?</v-card-text>
        <v-card-text v-else>Change mode of selected device(s)?</v-card-text>
        <v-card-actions>
          <v-btn flat @click="confirmDialog = false">CANCEL</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green" flat @click="processModes()" :loading="loading">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { OwnerProxyHelpers } from "@/library/helpers";
import plugModes from "./../mixins/plugModes";
import select from "./../mixins/select";
import helpers from "./../mixins/helpers";
import Api from "@library/apis/Api";

export default {
  mixins: [plugModes, select, helpers],
  data() {
    return {
      modePlugs: null,
      selectPlugsStep: false,
      confirmDialog: false,
      hasUsers: 0,
      key: "mode-plugs",
      show: false,
      tooltip: `
                <strong>Change the power settings of 1 or more of your devices.</strong>
                <ul class="ma-0 mt-2 ml-3 pa-0">
                <li class="mb-2">Smart: Allows you to control access, set billing rates,
                monitor your<br>device's status and power consumption</li>
                <li class="mb-2">Always On: Just like a regular device. Anyone can plug in</li>
                <li>Always Off: Disables your device. Plugging in doesn't do anything</li>
                </ul>`,
      enableRegexSearch: false,
      search: null,
    };
  },
  watch: {
    search(value) {
      this.selectAll = false;
      this.selected = [];
    },
  },
  computed: {
    ...mapState({
      role: (state) => state.Auth.role,
    }),
    ...mapGetters(["plugs", "accessPlans"]),
    ...mapGetters("PaymentPlan", { 
      hiddenPayments: "hiddenPayments",
      visiblePayments: "visiblePayments"
    }),
    allowChangeMode() {
      return OwnerProxyHelpers.isHaveAccessToFeature("DEVICE_CHANGE_MODE");
    },
    userPlugs() {
      const plugs = JSON.parse(JSON.stringify(this.plugs));
      let newPlugs = plugs.map((o) => {
        o.selected = this.selectAll;
        return o;
      });
      return newPlugs.filter((o) => {
        if (this.search === null) return true;
        if (!this.enableRegexSearch) {
          const search = this.search.toLowerCase();
          const device = (o.identifier + " - " + o.description).toLowerCase();
          return device.includes(search);
        } else {
          try {
            const search = new RegExp(this.search, "");
            const device = o.identifier + " - " + o.description;
            return device.match(search);
          } catch (error) {
            console.log(error.message);
            return false;
          }
        }
      });
    },
    selectedSubMode() {
      return this.subModes.find((o) => {
        return this.modeChange.ispublic === o.code;
      });
    },
    selectedPlan() {
      return this.accessPlans.find((o) => {
        return this.modeChange.plan_id === o.id;
      });
    },
  },
  methods: {
    clickCheckbox(evt, index) {
      const automaticallyClickCheckbox =
        evt.target.classList.contains("v-list__tile__title") ||
        evt.target.classList.contains("v-list__tile") ||
        evt.target.classList.contains("v-list__tile__action") ||
        evt.target.classList.contains("v-list__tile__content");
      if (automaticallyClickCheckbox) document.getElementById(`checkbox_${index}`).click();
    },
    filterModePlugs() {
      this.modeError = false;
      this.planError = false;
      this.selectError = false;
      if (this.modeChange.code === null) {
        this.selectError = true;
        return false;
      }
      if (this.modeChange.code >= 2 && this.modeChange.ispublic && !this.modeChange.plan_id) {
        this.planError = "Please select Payment Plan";
        return false;
      }

      this.selectPlugsStep = true;
    },
    confirmChange() {
      this.hasUsers = 0;
      this.userPlugs.forEach((o) => {
        if (o.active && o.selected) this.hasUsers = this.hasUsers + 1;
      });

      if (!this.selected.length) {
        this.modeError = "Please select at least 1 device";
        return false;
      }

      this.confirmDialog = true;
    },
    processModes() {
      this.$store.dispatch("loading", true);
      let to = this.modeChange;
      let requests = [];
      this.userPlugs.filter(p => p.selected).forEach((plug) => {
          requests.push(Api.plugSetMode(this.role, `{"plugId":"${plug.id}","mode":${to.code}}`));
        if (to.code > 1) {
          requests.push(Api.plugSetAccess(this.role, to.ispublic ? "public" : "private", to.ispublic ? `{"plugId":"${plug.id}","planId":"${to.plan_id}"}` : `{"plugId":"${plug.id}"}`));
        }
      })
      Promise.all(requests)
        .then(() => {
          setTimeout(() => {
            this.reset();
            this.back();
            this.$store.dispatch("snackbar", {
              message: "Device mode change request sent",
            });
            this.confirmDialog = false;
            this.$store.dispatch("loading", false);
          }, 1500);
        })
        .catch((error) => {
          setTimeout(() => {
            console.log(error);
            // this.$store.dispatch("loadPlugs"); // commented out for error cases
            this.modeError = error.response.data;
            if (error.response.status !== 500) {
              this.modeError = "Some devices failed to update";
            }
            this.confirmDialog = false;
            this.$store.dispatch("loading", false);
          }, 2000);
        });
    },
    reset() {
      this.modeChange.code = null;
      this.modeChange.ispublic = 0;
      this.modeChange.plan_id = null;
      this.selected = [];
      this.search = null;
      this.enableRegexSearch = false;
    },
    back() {
      this.modeError = null;
      this.selectAll = false;
      this.$store.dispatch("loadPlugs");
      this.selectPlugsStep = false;
    },
    dismissAlert(evt) {
      if (!evt) this.modeError = false;
    },
  },
  mounted() {
    this.modeChange.ispublic = 0;
    if (this.hiddenPayments.includes(this.modeChange.plan_id)) {
      this.modeChange.plan_id = null
    }
  },
};
</script>
<style lang="scss">
.search-slash-icon {
  color: rgba(0, 0, 0, 0.87);
}
.search-append-div {
  white-space: nowrap;
}
</style>
