<template>
  <v-card class="activate-plug-tile ma-2">
    <v-card-title><h3 id="change-password-tile">Change Password</h3></v-card-title>
    <v-alert :value="error" type="error" dismissible>
      {{ error }}
    </v-alert>
    <v-alert :value="success" type="success" dismissible>
      New password saved
    </v-alert>
    <v-card-text class="text-xs-center">
      <v-form ref="changePassForm" @submit="change()" v-model="valid">
        <v-text-field
          id="change-password-current-password-field"
          class="change-password-current-password"
          outline
          label="Current Password"
          ref="oldPasswordBox"
          prepend-inner-icon="vpn_key"
          :append-icon="show1 ? 'visibility' : 'visibility_off'"
          v-model="user.oldPassword"
          :error="error ? true : false"
          :type="show1 ? 'text' : 'password'"
          autocomplete="false"
          :rules="[rules.required]"
          @keyup.enter="change()"
          @click:append="show1 = !show1"
          required
          :disabled="busy || !hasAccess"
        ></v-text-field>
        <v-text-field
          id="change-password-new-password-field"
          class="change-password-new-password"
          outline
          label="New Password"
          ref="newPasswordBox"
          prepend-inner-icon="vpn_key"
          :append-icon="show2 ? 'visibility' : 'visibility_off'"
          v-model="user.newPassword"
          :error="error ? true : false"
          :type="show2 ? 'text' : 'password'"
          autocomplete="false"
          :rules="[rules.password]"
          @keyup.enter="change()"
          @click:append="show2 = !show2"
          required
          :disabled="busy || !hasAccess"
        ></v-text-field>
        <v-text-field
          id="change-password-confirm-new-password-field"
          class="change-password-confirm-new-password"
          outline
          label="Confirm New Password"
          ref="newPasswordBox2"
          prepend-inner-icon="vpn_key"
          :append-icon="show3 ? 'visibility' : 'visibility_off'"
          v-model="user.confirmPassword"
          :error="error ? true : false"
          :type="show3 ? 'text' : 'password'"
          autocomplete="false"
          :rules="[rules.password_confirm]"
          @keyup.enter="change()"
          @click:append="show3 = !show3"
          required
          :disabled="busy || !hasAccess"
        ></v-text-field>
        <v-btn id="change-password-save-button" class="plugzio-button mt-2" outline round @click="change()" :loading="busy" :disabled="busy || !hasAccess">
          SAVE
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import mixin from "./../mixins";
import Api from "../library/apis/Api";
import { OwnerProxyHelpers } from '@/library/helpers';

export default {
  mixins: [mixin],
  data() {
    return {
      user: {
        oldPassword: null,
        newPassword: null,
        confirmPassword: null,
      },
      rules: {
        password_confirm: (value) => {
          if (value) {
            return this.user.newPassword === this.user.confirmPassword || "Passwords does not match";
          } else {
            return "";
          }
        },
      },
      error: false,
      busy: false,
      show1: false,
      show2: false,
      show3: false,
      success: false,
      valid: true,
    };
  },
  computed: {
    hasAccess() {
      return OwnerProxyHelpers.isHaveAccessToFeature("SETTING_CHANGE_PASSWORD")
    }
  },
  methods: {
    change() {
      if (this.$refs.changePassForm.validate()) {
        this.busy = true;
        this.success = false;
        this.error = false;

        Api.changePassword(this.role, this.user)
          .then(() => {
            this.busy = false;
            this.success = true;
            this.$refs.changePassForm.reset();
          })
          .catch((error) => {
            this.busy = false;
            this.error = error.response.status === 400 ? "Invalid Current Password" : error.response.data;
          });
      }
    },
  },
};
</script>
