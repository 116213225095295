<template>
	<div class="text-xs-center" style="margin-top: 25vh">
		<v-icon size="40">phonelink_erase</v-icon>
		<h4 class="mt-4 text--muted">Device is not registered to a manager</h4>
	</div>
</template>

<script>
	export default {
		name: "DeviceNotRegisteredInfo",
	};
</script>
