<template>
  <div>
    <v-data-table
      :headers="logHeaders"
      :items="logs"
      :loading="busy"
      hide-actions
      class="elevation-1 ma-2"
      :pagination.sync="pagination"
    >
      <v-progress-linear v-slot:progress color="#29a64a" indeterminate></v-progress-linear>
      <template v-slot:no-data>
        {{ busy ? "Loading..." : "No transaction logs" }}
      </template>
      <template v-slot:items="props">
        <td>{{ props.item.log_timestamp_readable }}</td>
        <td>{{ props.item.new_expiry_time_readable }}</td>
        <td>{{ props.item.transaction_identifier }}</td>
        <td>{{ props.item.currency }}</td>
        <td>{{ props.item.currency_symbol }}{{ props.item.amount | numeral("0,0.00") }}</td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Api from "@library/apis/Api";
import { mapState } from "vuex";
export default {
  props: ["plug", "show", "timeFormat"],
  data() {
    return {
      logs: [],
      logHeaders: [
        { text: "Transaction Time", value: "log_timestamp" },
        { text: "New Expiry Time", value: "new_expiry_time" },
        { text: "Transaction Identifier", value: "transaction_identifier" },
        { text: "Currency", value: "currency" },
        { text: "Amount", value: "amount" },
      ],
      busy: false,
      currentTime: {
        since: null,
        till: null,
      },
      pagination: {
        descending: true,
        sortBy: 'log_timestamp',
        page: 1,
        rowsPerPage: 100,
        totalItems: 0
      }
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.Auth.role,
    }),
    storedTime() {
      return this.$store.getters.storedTime;
    },
  },
  methods: {
    showLog() {
      this.busy = true;
      this.currentTime = this.storedTime;
      let data = {
        plugId: this.plug.id,
        since: this.storedTime.since,
        till: this.storedTime.till,
      };
      Api.plugBillingPaymentLog(this.role, data)
        .then((logs) => {
          let data = logs || [];
          this.logs = data.map((o) => {
            o.amount = o.credit || o.debit;
            o.currency_symbol = o.currency === "USD" || o.currency === "CAD" ? "$ " : "";
            o.log_timestamp_readable = this.$moment.unix(o.log_timestamp).format(this.timeFormat);
            o.new_expiry_time_readable = this.$moment.unix(o.new_expiry_time).format(this.timeFormat);
            return o;
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.busy = false;
        });
    },
    fetchLog() {
      if (this.show) this.showLog();
    },
  },
  watch: {
    show() {
      this.fetchLog();
    },
    storedTime() {
      this.fetchLog();
    },
  },
};
</script>
